import { createJSONStorage, persist } from 'zustand/middleware'
import { create } from 'zustand'

import { Locale, isSupportedLocale } from 'i18n'

export const LOCALE_LABELS = [
  {
    code: 'en',
    text: 'EN',
  },
  {
    code: 'de',
    text: 'DE',
  },
  {
    code: 'es',
    text: 'ES',
  },
  {
    code: 'nl',
    text: 'NL',
  },
  {
    code: 'ar',
    text: 'AR',
  },
  {
    code: 'cr',
    text: 'CR',
  },
]

// type Hook = () => [Locale, (locale: Locale) => void]

type LocaleStore = {
  locale: Locale
  setLocale: (locale: Locale) => void
}

// const stateLink = createState<Locale>(
//   (navigator.language.slice(0, 2) as Locale) !== null
//     ? (navigator.language.slice(0, 2) as Locale)
//     : 'en',
// ).attach(Persistence('locale-key'))
const currentLocale = navigator.language.slice(0, 2) as Locale

export const useLocaleStore = create<LocaleStore>()(
  persist(
    (set) => ({
      locale: isSupportedLocale(currentLocale) ? currentLocale : 'en',
      setLocale: (locale: Locale) => {
        set({ locale })
      },
    }),
    { name: 'locale-key', storage: createJSONStorage(() => localStorage) },
  ),
)

export const useLocale = (): [Locale, (locale: Locale) => void] => {
  const state = useLocaleStore()
  return [state.locale, state.setLocale]
}
