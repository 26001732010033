import ar from 'i18n/messages/ar.json'
import cr from 'i18n/messages/cr.json'
import de from 'i18n/messages/de.json'
import en from 'i18n/messages/en.json'
import es from 'i18n/messages/es.json'
import nl from 'i18n/messages/nl.json'

export type Locale = 'en' | 'de' | 'es' | 'nl' | 'ar' | 'cr'

export const isSupportedLocale = (locale: string): locale is Locale => {
  return ['en', 'de', 'es', 'nl', 'ar'].includes(locale)
}

type Messages = {
  en: typeof en
  de: typeof de
  es: typeof es
  nl: typeof nl
  ar: typeof ar
  cr: typeof cr
}

export const messages: Messages = {
  en,
  de,
  es,
  nl,
  ar,
  cr,
}
